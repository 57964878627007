import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Badge } from "flwww";
import { AppContext } from "./context/AppContext";
import { auth } from "./Firebase/firebase";
export const NavBar = (props) => {
  const App = useContext(AppContext);

  return (
    <div className="nav-filter-bar">
      <div className="links">
        {!App.viewer ? <React.Fragment>
          <NavLink
            activeClassName="tcs-active"
            to={`/main/dashboard`}
          >
            <Badge dot={App.unaddressed > 0} style={{ border: "none" }}>
              Pending
          </Badge>
          </NavLink>

          <NavLink
            activeClassName="tcs-active"
            to={`/main/today`}
          >
            <Badge dot={App.unaddressedToday > 0} style={{ border: "none" }}>
              Today
          </Badge>
          </NavLink>
          <NavLink
            activeClassName="tcs-active"
            to={`/main/history`}
          >
            History
        </NavLink>
          <a target="_#" href={App.outlet == "TCS" ? "https://tcs-reservation.spiceoflife.sg/#/" : "https://cmpb-reservation.spiceoflife.sg/#/"} >
            Create
        </a>
        </React.Fragment> : null}
        <NavLink
          activeClassName="tcs-active"
          to={{
            pathname: `/main/floor`,
            state: { date: new Date() },
          }}
        >
          Tables
        </NavLink>
        <NavLink activeClassName="tcs-active" to={`/main/ops`}>
          Ops
        </NavLink>
        {App.admin ? <NavLink activeClassName="tcs-active" to={`/main/admin`}>
          Admin
        </NavLink> : null}

      </div>
    </div>
  );
};
