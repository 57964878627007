import React, { Component } from "react";
import { DatePickerInput } from "rc-datepicker";
import { db } from "../Firebase/firebase";
import { withRouter } from "react-router-dom";
import { AppContext } from '../context/AppContext';
import List from "./List";
import Areas from './Areas';
import FloorImg from '../../images/floorplan.jpeg'


class Floor extends Component {
  static contextType = AppContext;
  state = {
    reservations: [],
    loading: true,
    date: "",
    dates: [],
    currentTab: 0,
  };
  componentDidMount() {
    try {
      this.onDateChange(this.props.location.state.date);
    } catch (e) {
      this.onDateChange(new Date());
    }
    /*
    if (this.props.location.state.date !== undefined) {
      this.onDateChange(this.props.location.state.date);
    } else {
      this.onDateChange(new Date());
    }*/
  }
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    db.collection("notes")
      .doc(this.state.noteId)
      .update({ [e.target.id]: e.target.value });
  };
  onDateChange = (jsDate, dateString) => {
    let newDates = [];
    this.setState(
      {
        date: jsDate,
      },
      () => {
        if (this.state.date !== "") {
          let { date } = this.state;
          let day = date.getDate();
          let month = date.getMonth();
          let year = date.getFullYear();
          db.collection("reservations")
            .where("outlet", "==", this.context.outlet)
            .where("date", ">=", new Date(year, month, day, 0, 0, 0, 0))
            .where("date", "<=", new Date(year, month, day, 23, 0, 0, 0))
            .onSnapshot((querySnapshot) => {
              let data = [];
              querySnapshot.forEach((doc) => {
                let info = doc.data();
                data.push({ ...info, id: doc.id });
              });
              this.setState({
                reservations: data,
                loading: false,
                dates: data,
              });
            });
          db.collection("notes")
            .where("outlet", "==", this.context.outlet)
            .where("date", ">=", new Date(year, month, day, 0, 0, 0, 0))
            .where("date", "<=", new Date(year, month, day, 23, 0, 0, 0))
            .onSnapshot((querySnapshot) => {
              console.log(querySnapshot)
              if (querySnapshot.empty) {
                db.collection("notes").add({
                  createdAt: new Date(),
                  outlet: this.context.outlet,
                  date: jsDate,
                  notes: '',
                })
              }
              else {
                let data = [];
                querySnapshot.forEach((doc) => {
                  let info = doc.data();
                  data.push({ ...info, id: doc.id });
                });
                console.log(data);
                this.setState({
                  notes: data[0].notes,
                  noteId: data[0].id
                });
              }

            });
        }
      }
    );
  };

  /*onDateChange = (jsDate, dateString) => {
    let newDates = [];
    this.setState(
      {
        date: jsDate,
      },
      () => {
        if (this.state.date !== "") {
          let { date } = this.state;
          let calDate;
          let reserveDate;
          calDate = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
          newDates = this.state.reservations.filter((reserve) => {
            reserveDate = `${reserve.date
              .toDate()
              .getFullYear()}-${reserve.date
              .toDate()
              .getMonth()}-${reserve.date.toDate().getDate()}`;
            return reserveDate === calDate;
          });
          this.setState({ dates: newDates });
        }
      }
    );
  };*/
  sortTime = (dataArray) => {
    let timeAdded = dataArray.map((data) => {
      let date = data.date.toDate();
      let timestring = data.time;
      timestring = timestring.split(":");
      let hour = parseInt(timestring[0]);
      let minute = parseInt(timestring[1]);
      let timeInt = hour * 100 + minute;
      date.setHours(hour, minute);
      let expiry = new Date(date.getTime() + 10 * 60000);
      data.timeNumber = timeInt;
      data.dateTime = expiry;
      return data;
    });

    let timeSorted = timeAdded.sort((a, b) => {
      return a.timeNumber - b.timeNumber;
    });
    let filtered = timeSorted.filter((entry) => {
      return entry.confirmed || entry.declined || entry.cancelled;
    });
    let confirmed = filtered.filter(entry => {
      return !entry.declined;
    })
    return confirmed;
  };
  toggleTimes = () => {
    this.setState({ filter: "timing", timeFilter: this.state.timeFilter === 2 ? 0 : this.state.timeFilter + 1 })
  }
  toggleSeats = () => {
    this.setState({ filter: "seats", seatFilter: this.state.seatFilter === 2 ? 0 : this.state.seatFilter + 1 })
  }
  handleCheck = () => {
    this.setState({ showCancelled: !this.state.showCancelled })
  }
  render() {
    let { date, dates, currentTab } = this.state;
    return (
      <div className="floorplan">
        <div className="floor-header">
          <div className="today-notes">
            <label htmlFor="notes">Today's Event:</label>
            <textarea name="notes" id="notes" onChange={this.onChange} value={this.state.notes}>{this.state.notes}</textarea>
          </div>
          <div style={{ marginRight: 8, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <div className="dash-bar">
              <div className="dates">
                <DatePickerInput
                  value={date}
                  onChange={this.onDateChange}
                ></DatePickerInput>
              </div>
            </div>
            <div className="tab-links">
              <button className={currentTab === 0 ? "tcs-active" : "nil"} onClick={() => this.setState({ currentTab: 0 })} >List</button>
              <button className={currentTab === 1 ? "tcs-active" : "nil"} onClick={() => this.setState({ currentTab: 1 })} >Layout</button>
              <button className={currentTab === 2 ? "tcs-active" : "nil"} onClick={() => this.setState({ currentTab: 2 })} >Floorplan</button>
            </div>
          </div>
        </div>
        {!this.context.isMobile ? <hr style={{ borderBlockColor: "#000", marginTop: "1em" }} /> : null}
        {currentTab === 0 ? <List reservations={dates}></List> : currentTab === 1 ? <Areas reservations={dates.filter(item => item.tableNo)} blanks={dates.filter(item => !item.tableNo || item.tableNo == "")}></Areas> : <img style={{ width: "100%" }} src={FloorImg}></img>}
        {/* <List reservations={dates}></List> */}

      </div>
    );
  }
}
export default withRouter(Floor);
