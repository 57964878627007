import React, { Component } from "react";
import Dashboard from "./dashboard/Dashboard";
import Confirmation from "./create/Confirmation";
import Today from "./date/Today";
import Floor from "./operations/Floor";
import History from "./dashboard/History";
import Edit from "./create/Edit";
import { Route } from "react-router-dom";
import { NavBar } from "./NavBar";
import Ops from "./operations/Ops";
import { AppContext } from './context/AppContext';
import { Helmet } from 'react-helmet'
import Admin from "./admin/Admin";
import { Drawer } from 'flwww';

export default class Outlet extends Component {
  static contextType = AppContext;
  state = {
    drawerIsVisible: false,
  }
  toggleDrawer = () => {
    this.setState(prevState => ({ drawerIsVisible: !prevState.drawerIsVisible }))
  }
  render() {
    let { outlet, isMobile } = this.context;
    return (
      <div className="outlet">
        {this.context.isMobile ? <button className="drawer-btn" onClick={this.toggleDrawer}><i class="im im-menu"></i></button> : null}
        <Helmet>
          <title>{this.context.outlet}</title>
        </Helmet>
        {outlet === "TCS" ? <img className="outlet-logo" src={require(`../images/${outlet}.png`)}></img> : <img id="inverse-logo" src={require(`../images/${outlet}.png`)}></img>}
        <span id="version">3.0.1</span>
        {isMobile ? <Drawer style={{ backgroundColor: "#e5c88b" }} showDrawer={this.state.drawerIsVisible}
          toggleDrawer={this.toggleDrawer}>
          <NavBar></NavBar>
        </Drawer> : <NavBar></NavBar>}
        <Route
          path={`/main/dashboard`}
          render={(props) => (
            <Dashboard
              {...props}
              setCount={() => this.setCount}

            />
          )}
        />
        <Route
          path={`/main/edit/:reserve`}
          render={(props) => (
            <Edit {...props} />
          )}
        />
        <Route
          path={`/main/today`}
          render={(props) => <Today {...props} />}
        />
        <Route
          path={`/main/history`}
          render={(props) => <History {...props} />}
        />
        <Route
          path={`/main/floor`}
          render={(props) => <Floor {...props} />}
        />
        <Route
          path={`/main/ops`}
          render={(props) => <Ops {...props} />}
        />
        <Route
          path={`/main/admin`}
          render={(props) => <Admin {...props} />}
        />

        <Route
          path={`/main/confirmation`}
          render={(props) => (
            <Confirmation {...props} />
          )}
        />
      </div>
    );
  }
}
