import React, { Component } from 'react'
import Area from './Area';
import AreaRow from './AreaRow';
export default class Areas extends Component {
    state = {
        areas: [
            { name: "Cafe", code: 'C', tables: ['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7'] },
            { name: "Lounge", code: 'L', tables: ['L1', 'L2', 'L3', 'L4', 'L5', 'L6', 'L7'] },
            { name: "Dining", code: 'D', tables: ['D2', 'D3', 'D4', 'D5', 'D6', 'D7', 'D8', 'D9', 'D10', 'D11', 'D12'] },
            { name: "Outdoor", code: 'A', tables: ['A14', 'A15', 'A16', 'A17', 'A18', 'A19', 'A20'] }]
    }
    sortTime = (dataArray) => {
        let timeAdded = dataArray.map((data) => {
            let date = data.date.toDate();
            let timestring = data.time;
            timestring = timestring.split(":");
            let hour = parseInt(timestring[0]);
            let minute = parseInt(timestring[1]);
            let timeInt = hour * 100 + minute;
            date.setHours(hour, minute);
            let expiry = new Date(date.getTime() + 10 * 60000);
            data.timeNumber = timeInt;
            data.dateTime = expiry;
            return data;
        });

        let timeSorted = timeAdded.sort((a, b) => {
            return a.timeNumber - b.timeNumber;
        });
        let filtered = timeSorted.filter((entry) => {
            return entry.confirmed || entry.declined || entry.cancelled;
        });
        let confirmed = filtered.filter(entry => {
            return !entry.declined;
        })
        return confirmed;
    };
    render() {
        let { reservations, blanks } = this.props;
        let { areas } = this.state;
        let allTables = [...areas[0].tables, ...areas[1].tables, ...areas[2].tables, ...areas[3].tables];
        let otherData = this.sortTime(reservations).filter(item => {
            return allTables.indexOf(item.tableNo) === -1;
        })
        let others = [...otherData, ...blanks];
        return (
            <div className="floor-area-page">
                {this.state.areas.map(area => {
                    let areaData = this.sortTime(reservations).filter(item => {
                        let tableCode = item.tableNo.charAt(0);
                        return tableCode === area.code;
                    })
                    return <Area areaName={area.name} tables={area.tables} reservations={areaData}></Area>
                })}
                <div className="area others">
                    <h3>Others</h3>
                    <div></div>
                    <div className="area-box">
                        {this.sortTime(others).map(item => {
                            return <AreaRow data={item} others={true} current={new Date}></AreaRow>;
                        })}
                    </div>
                </div>
            </div>
        )
    }
}
