import React, { Component } from "react";
import { db } from "../Firebase/firebase";
import ReserveData from "./ReserveData";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import Switch from "react-switch";
import FilterResults from "react-filter-search";
import { AppContext } from "../context/AppContext";
import { Checkbox, Drawer } from "flwww";
//import "rc-datepicker/lib/style.css";

export default class Dashboard extends Component {
  static contextType = AppContext;
  state = {
    reservations: [],
    loading: true,
    date: "",
    count: 0,
    dates: [],
    sort: true,
    searchValue: "",
    onlyUnaddressed: false,
    drawerIsVisible: false,
  };
  componentDidMount() {
    let day = new Date().getDate();
    let month = new Date().getMonth();
    let year = new Date().getFullYear();
    db.collection("reservations")
      .where("outlet", "==", this.context.outlet)
      .where("date", ">=", new Date(year, month, day, 0, 0, 0, 0))
      .orderBy("date", "asc")
      .limit(1000)
      .onSnapshot((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let info = doc.data();
          data.push({ ...info, id: doc.id });
        });
        this.setState(
          {
            reservations: data,
            loading: false,
            dates: data,
            count: data.length,
            unAddressed: data.filter((entry) => {
              return !entry.confirmed && !entry.cancelled && !entry.declined;
            }),
            unAddressedToday: data.filter((entry) => {
              return !entry.confirmed && !entry.cancelled && !entry.declined && entry.date.toDate().getDate() == new Date().getDate();
            }),
          },
          () => {
            this.context.setCount(this.state.unAddressed.length);
            this.context.setCountToday(this.state.unAddressedToday.length);
          }
        );
      });
  }

  onReset = () => {
    this.setState({
      date: "",
      dates: this.state.reservations,
      count: this.state.reservations.length,
    });
  };

  sortTime = (dataArray) => {
    let timeAdded = dataArray.map((data) => {
      let date = data.date.toDate();
      let timestring = data.time;
      timestring = timestring.split(":");
      let hour = parseInt(timestring[0]);
      let minute = parseInt(timestring[1]);
      let timeInt = hour * 100 + minute;
      date.setHours(hour, minute);
      let expiry = new Date(date.getTime() + 10 * 60000);
      data.timeNumber = timeInt;
      data.dateTime = expiry;
      return data;
    });

    let timeSorted = timeAdded.sort((a, b) => {
      return a.timeNumber - b.timeNumber;
    });

    return timeSorted;
  };

  onDateChange = (jsDate, dateString) => {
    let newDates = [];
    this.setState(
      {
        date: jsDate,
      },
      () => {
        if (this.state.date !== "") {
          let { date } = this.state;
          let calDate;
          let reserveDate;
          calDate = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
          newDates = this.state.reservations.filter((reserve) => {
            reserveDate = `${reserve.date
              .toDate()
              .getFullYear()}-${reserve.date
                .toDate()
                .getMonth()}-${reserve.date.toDate().getDate()}`;
            return reserveDate === calDate;
          });
          this.setState({ dates: newDates, count: newDates.length });
        }
      }
    );
  };
  addCount = () => {
    let current = this.state.count;
    this.setState({
      count: current + 1,
    });
  };

  changeSort = (sort) => {
    this.setState({ sort });
  };
  handleCheck = () => {
    this.setState({ onlyUnaddressed: !this.state.onlyUnaddressed })
  }

  toggleDrawer = () => {
    this.setState(prevState => ({ drawerIsVisible: !prevState.drawerIsVisible }))
  }
  /* {!this.state.loading
          ? this.state.reservations.map(each => {
              if (this.state.date == "") {
                return <ReserveData key={each.id} data={each}></ReserveData>;
              } else {
                let reserveDate = `${each.date
                  .toDate()
                  .getFullYear()}-${each.date
                  .toDate()
                  .getMonth()}-${each.date.toDate().getDate()}`;
                if (reserveDate === calDate) {
                  return <ReserveData key={each.id} data={each}></ReserveData>;
                } else {
                  console.log("fail");
                  return null;
                }
              }
            })
          : null} */
  render() {
    let { date, dates, searchValue, onlyUnaddressed } = this.state;
    let unAddressed = [...dates];
    unAddressed = unAddressed.filter((entry) => {
      return !entry.confirmed && !entry.cancelled && !entry.declined;
    });
    let submissionDates = onlyUnaddressed ? unAddressed : [...dates];
    submissionDates = submissionDates.sort((dateA, dateB) => {
      return dateB.createdAt - dateA.createdAt;
    });
    let sortedTimes = onlyUnaddressed ? unAddressed : [...dates];
    sortedTimes = sortedTimes.map(item => {
      let timestring = item.time;
      timestring = timestring.split(":");
      let hour = parseInt(timestring[0]);
      let minute = parseInt(timestring[1]);
      item.sortDateTime = item.date.toDate();
      item.sortDateTime.setHours(hour, minute);
      return item;
    }).sort((a, b) => {
      return a.sortDateTime - b.sortDateTime;
    })
    let calDate;
    if (this.state.date !== "") {
      calDate = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
    }
    return (
      <div className="dashboard">
        {this.context.isMobile ? <React.Fragment><Drawer position={"right"} style={{ backgroundColor: "#e5c88b" }} showDrawer={this.state.drawerIsVisible}
          toggleDrawer={this.toggleDrawer}>
          <div id="unaddressed" style={{ display: "flex", width: 100, padding: "8px" }}>
            <label style={{ color: "black", fontWeight: "bold", marginRight: "8px" }} htmlFor="">Unaddressed</label>
            <Checkbox
              name="checkbox"
              onChange={this.handleCheck}
              checked={this.state.onlyUnaddressed}
            ></Checkbox>
          </div>
          <Switch
            checked={this.state.sort}
            onChange={this.changeSort}
            handleDiameter={32}
            offColor="#e6a44a"
            onColor="#e6a44a"
            offHandleColor="#ebc88b"
            onHandleColor="#ebc88b"
            height={60}
            width={160}
            borderRadius={2}
            activeBoxShadow="0px 0px 1px 2px #fffc35"
            uncheckedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 15,
                  color: "#000",
                  paddingRight: 2,
                  marginRight: 24,
                }}
              >
                Reservation
              </div>
            }
            checkedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 15,
                  color: "#000",
                  paddingRight: 2,
                  marginLeft: 24
                }}
              >
                Submission
              </div>
            }
            uncheckedHandleIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 20
                }}
              >
                ☹
              </div>
            }
            checkedHandleIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  color: "red",
                  fontSize: 18
                }}
              >
                ♥
              </div>
            }
            className="react-switch"
            id="small-radius-switch"
          />
          <button className="drawer-filter-close" onClick={this.toggleDrawer}>Apply</button>
        </Drawer><button className="filter-drawer-toggle" onClick={this.toggleDrawer}><i class="im im-filter"></i></button></React.Fragment> : null}

        <div className="dash-bar">
          {!this.context.isMobile ?
            <div className="sorts">

              <React.Fragment><div className="unaddressed-filter">
                <label style={{ color: "black", fontWeight: "bold", marginRight: "8px" }} htmlFor="">Only Unaddressed</label>
                <Checkbox
                  name="checkbox"
                  onChange={this.handleCheck}
                  checked={this.state.onlyUnaddressed}
                ></Checkbox>
              </div><Switch
                  checked={this.state.sort}
                  onChange={this.changeSort}
                  handleDiameter={32}
                  offColor="#e6a44a"
                  onColor="#e6a44a"
                  offHandleColor="#ebc88b"
                  onHandleColor="#ebc88b"
                  height={60}
                  width={160}
                  borderRadius={2}
                  activeBoxShadow="0px 0px 1px 2px #fffc35"
                  uncheckedIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 15,
                        color: "#000",
                        paddingRight: 2,
                        marginRight: 24,
                      }}
                    >
                      Reservation
                    </div>
                  }
                  checkedIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 15,
                        color: "#000",
                        paddingRight: 2,
                        marginLeft: 24
                      }}
                    >
                      Submission
                    </div>
                  }
                  uncheckedHandleIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 20
                      }}
                    >
                      ☹
                    </div>
                  }
                  checkedHandleIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        color: "red",
                        fontSize: 18
                      }}
                    >
                      ♥
                    </div>
                  }
                  className="react-switch"
                  id="small-radius-switch"
                /></React.Fragment></div> : null
          }




          <input
            id="search-bar"
            type="text"
            value={searchValue}
            onChange={(event) => {
              this.setState({ searchValue: event.target.value });
            }}
            placeholder="search"
          />
          <h3 className="unaddressed">{unAddressed.length}</h3>
        </div>

        {/*!this.state.loading
          ? this.state.sort
            ? submissionDates.map((each) => {
                return <ReserveData key={each.id} data={each}></ReserveData>;
              })
            : dates.map((each) => {
                return <ReserveData key={each.id} data={each}></ReserveData>;
              })
          : null */}
        {!this.state.loading ? (
          this.state.sort ? (
            <FilterResults
              value={searchValue}
              data={submissionDates}
              renderResults={(results) => {
                return (
                  <React.Fragment>
                    {results.map((item) => {
                      return (
                        <ReserveData data={item} key={item.id}></ReserveData>
                      );
                    })}
                  </React.Fragment>
                );
              }}
            />
          ) : (
            <FilterResults
              value={searchValue}
              data={sortedTimes}
              renderResults={(results) => {
                return (
                  <React.Fragment>
                    {results.map((item) => {
                      return (
                        <ReserveData data={item} key={item.id}></ReserveData>
                      );
                    })}
                  </React.Fragment>
                );
              }}
            />
          )
        ) : null}
      </div>
    );
  }
}
