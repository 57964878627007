import React, { Component } from "react";
import { db } from "../Firebase/firebase";
import ReserveData from "../dashboard/ReserveData";
import Switch from "react-switch";
import FilterResults from "react-filter-search";
import { AppContext } from "../context/AppContext";

export default class Today extends Component {
  static contextType = AppContext;
  state = {
    reservations: [],
    loading: true,
    searchValue: "",
    sort: true,
  };

  sortTime = (dataArray) => {
    let timeAdded = dataArray.map((data) => {
      let date = data.date.toDate();
      let timestring = data.time;
      timestring = timestring.split(":");
      let hour = parseInt(timestring[0]);
      let minute = parseInt(timestring[1]);
      let timeInt = hour * 100 + minute;
      date.setHours(hour, minute);
      let expiry = new Date(date.getTime() + 10 * 60000);
      data.timeNumber = timeInt;
      data.dateTime = expiry;
      return data;
    });

    let timeSorted = timeAdded.sort((a, b) => {
      return a.timeNumber - b.timeNumber;
    });

    return timeSorted;
  };


  componentDidMount() {
    let day = new Date().getDate();
    let month = new Date().getMonth();
    let year = new Date().getFullYear();
    db.collection("reservations")
      .where("outlet", "==", this.context.outlet)
      .where("date", ">=", new Date(year, month, day, 0, 0, 0, 0))
      .where("date", "<=", new Date(year, month, day, 23, 0, 0, 0))
      .onSnapshot((querySnapshot) => {
        console.log(querySnapshot);
        let data = [];
        querySnapshot.forEach((doc) => {
          let info = doc.data();
          data.push({ ...info, id: doc.id });
        });
        this.setState({
          reservations: data,
          loading: false,
        });
      });
  }
  changeSort = (sort) => {
    this.setState({ sort });
  };
  render() {
    let { searchValue, reservations } = this.state;
    let submissionDates = [...reservations];
    submissionDates = submissionDates.sort((dateA, dateB) => {
      return dateB.createdAt - dateA.createdAt;
    });

    return (
      <div className="dashboard">
        <div className="dash-bar">
          <div className="sorts">
            {!this.context.isMobile ? <span style={{ marginRight: 5 }}>Reservation Date</span> : null}
            {this.context.isMobile ?
              <Switch
                checked={this.state.sort}
                onChange={this.changeSort}
                handleDiameter={32}
                offColor="#e6a44a"
                onColor="#e6a44a"
                offHandleColor="#ebc88b"
                onHandleColor="#ebc88b"
                height={60}
                width={160}
                borderRadius={2}
                activeBoxShadow="0px 0px 1px 2px #fffc35"
                uncheckedIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 15,
                      color: "#000",
                      paddingRight: 2,
                      marginRight: 24,
                    }}
                  >
                    Reservation
                  </div>
                }
                checkedIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 15,
                      color: "#000",
                      paddingRight: 2,
                      marginLeft: 24
                    }}
                  >
                    Submission
                  </div>
                }
                uncheckedHandleIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 20
                    }}
                  >
                    ☹
                  </div>
                }
                checkedHandleIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      color: "red",
                      fontSize: 18
                    }}
                  >
                    ♥
                  </div>
                }
                className="react-switch"
                id="small-radius-switch"
              /> : <Switch
                onChange={this.changeSort}
                checked={this.state.sort}
                onColor="#e6a44a"
                onHandleColor="#f0c892"
                handleDiameter={30}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={48}
                className="react-switch"
                id="material-switch"
              ></Switch>
            }
            {!this.context.isMobile ? <span style={{ marginLeft: 5 }}>Submission Date</span> : null}
          </div>
          <h3 className="unaddressed">{this.context.unaddressedToday}</h3>
          <input
            id="search-bar"
            type="text"
            value={this.state.searchValue}
            onChange={(event) => {
              this.setState({ searchValue: event.target.value });
            }}
            placeholder="search"
          />
        </div>
        {!this.state.loading ? (
          this.state.sort ? (
            <FilterResults
              value={searchValue}
              data={submissionDates}
              renderResults={(results) => {
                return (
                  <React.Fragment>
                    {results.map((item) => {
                      return (
                        <ReserveData data={item} key={item.id}></ReserveData>
                      );
                    })}
                  </React.Fragment>
                );
              }}
            />
          ) : (
            <FilterResults
              value={searchValue}
              data={this.sortTime(reservations)}
              renderResults={(results) => {
                return (
                  <React.Fragment>
                    {results.map((item) => {
                      return (
                        <ReserveData data={item} key={item.id}></ReserveData>
                      );
                    })}
                  </React.Fragment>
                );
              }}
            />
          )
        ) : null}
      </div>
    );
  }
}
