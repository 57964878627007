import React, { Component } from 'react';
import TableRow from './TableRow';
import { Checkbox, Drawer } from "flwww";
import { AppContext } from "../context/AppContext";


export default class List extends Component {
    static contextType = AppContext;
    state = {
        sort: true,
        searchValue: "",
        filter: "timing",
        timeFilter: 0,
        seatFilter: 0,
        showCancelled: true,
        drawerIsVisible: false,
    }

    sortTime = (dataArray) => {
        let timeAdded = dataArray.map((data) => {
            let date = data.date.toDate();
            let timestring = data.time;
            timestring = timestring.split(":");
            let hour = parseInt(timestring[0]);
            let minute = parseInt(timestring[1]);
            let timeInt = hour * 100 + minute;
            date.setHours(hour, minute);
            let expiry = new Date(date.getTime() + 10 * 60000);
            data.timeNumber = timeInt;
            data.dateTime = expiry;
            return data;
        });

        let timeSorted = timeAdded.sort((a, b) => {
            return a.timeNumber - b.timeNumber;
        });
        let filtered = timeSorted.filter((entry) => {
            return entry.confirmed || entry.declined || entry.cancelled;
        });
        let confirmed = filtered.filter(entry => {
            return !entry.declined;
        })
        return confirmed;
    };
    toggleTimes = () => {
        this.setState({ filter: "timing", timeFilter: this.state.timeFilter === 2 ? 0 : this.state.timeFilter + 1 })
    }
    toggleSeats = () => {
        this.setState({ filter: "seats", seatFilter: this.state.seatFilter === 2 ? 0 : this.state.seatFilter + 1 })
    }
    handleCheck = (e) => {
        this.setState({ [e.target.name]: e.target.checked })
    }

    handleFilters = (e) => {
        switch (e.target.name) {
            case 'alltimes':
                this.setState({
                    timeFilter: 0,
                    filter: "timing"
                })
                break;
            case 'before5':
                this.setState({
                    timeFilter: 1,
                    filter: "timing"
                })
                break;
            case 'after5':
                this.setState({
                    timeFilter: 2,
                    filter: "timing"
                })
                break;
            case 'allseats':
                this.setState({
                    seatFilter: 0,
                    filter: "seating"
                })
                break;
            case 'indoor':
                this.setState({
                    seatFilter: 1,
                    filter: "seating"
                })
                break;
            case 'outdoor':
                this.setState({
                    seatFilter: 2,
                    filter: "seating"
                })
                break;
        }
    }

    toggleDrawer = () => {
        this.setState(prevState => ({ drawerIsVisible: !prevState.drawerIsVisible }))
    }

    render() {
        let { reservations } = this.props;
        const columns = [
            "Time",
            "Name",
            "Mobile",
            "Pax",
            "Seat",
            "Turnover",
            "TableNo",
        ];

        let times = [...reservations];
        let allTimes = this.sortTime(times);
        if (!this.state.showCancelled) {
            allTimes = allTimes.filter(item => {
                return !item.cancelled;
            })
        }
        let timesBefore5 = allTimes.filter((entry) => {
            return entry.timeNumber < 1700;
        });
        let timesAfter5 = allTimes.filter((entry) => {
            return entry.timeNumber > 1700;
        });
        let indoor = allTimes.filter((entry) => {
            return entry.seatPref === 'indoor';
        });
        let outdoor = allTimes.filter((entry) => {
            return entry.seatPref === 'outdoor';
        });
        let filterTimes = [{ filter: "None", data: allTimes }, { filter: "Before 5", data: timesBefore5 }, { filter: "After 5", data: timesAfter5 }];
        let filterSeats = [{ filter: "None", data: allTimes }, { filter: "Indoor", data: indoor }, { filter: "Outdoor", data: outdoor }];
        return (
            <div className="table-list">

                {this.context.isMobile ? <React.Fragment><button className="filter-drawer-toggle" onClick={this.toggleDrawer}><i class="im im-filter"></i></button><Drawer position={"right"} style={{ backgroundColor: "#e5c88b" }} showDrawer={this.state.drawerIsVisible}
                    toggleDrawer={this.toggleDrawer}>
                    <div className="filter-drawer-row">
                        <Checkbox
                            name="showCancelled"
                            onChange={this.handleCheck}
                            checked={this.state.showCancelled}
                        ></Checkbox>
                        <h3 style={{ marginLeft: 8 }}>Show Cancellations</h3>
                    </div>
                    <hr />
                    <div className="filter-drawer-row">
                        <Checkbox
                            name="alltimes"
                            onChange={this.handleFilters}
                            checked={this.state.timeFilter === 0}
                        ></Checkbox>
                        <h3 style={{ marginLeft: 8 }}>All Day</h3>
                    </div>
                    <div className="filter-drawer-row">
                        <Checkbox
                            name="before5"
                            onChange={this.handleFilters}
                            checked={this.state.timeFilter === 1 && this.state.filter === "timing"}
                        ></Checkbox>
                        <h3 style={{ marginLeft: 8 }}>Before 5PM</h3>
                    </div>
                    <div className="filter-drawer-row">
                        <Checkbox
                            name="after5"
                            onChange={this.handleFilters}
                            checked={this.state.timeFilter === 2 && this.state.filter === "timing"}
                        ></Checkbox>
                        <h3 style={{ marginLeft: 8 }}>After 5PM</h3>
                    </div>
                    <hr />
                    <div className="filter-drawer-row">
                        <Checkbox
                            name="allseats"
                            onChange={this.handleFilters}
                            checked={this.state.seatFilter === 0}
                        ></Checkbox>
                        <h3 style={{ marginLeft: 8 }}>Indoor/Outdoor</h3>
                    </div>
                    <div className="filter-drawer-row">
                        <Checkbox
                            name="indoor"
                            onChange={this.handleFilters}
                            checked={this.state.seatFilter === 1 && this.state.filter === "seating"}
                        ></Checkbox>
                        <h3 style={{ marginLeft: 8 }}>Indoor Only</h3>
                    </div>
                    <div className="filter-drawer-row">
                        <Checkbox
                            name="outdoor"
                            onChange={this.handleFilters}
                            checked={this.state.seatFilter === 2 && this.state.filter === "seating"}
                        ></Checkbox>
                        <h3 style={{ marginLeft: 8 }}>Outdoor Only</h3>
                    </div>
                </Drawer></React.Fragment> : null}
                {!this.context.isMobile ? <div className="floor-bar">
                    <div className="filter-name">
                        <h3 style={{ marginLeft: 16, marginRight: 16 }}>Filter - {this.state.filter === "timing" ? filterTimes[this.state.timeFilter].filter : filterSeats[this.state.seatFilter].filter}</h3>
                        <Checkbox
                            name="checkbox"
                            onChange={this.handleCheck}
                            checked={this.state.showCancelled}
                        ></Checkbox>
                        <h3 style={{ marginLeft: 8 }}>Show Cancellations</h3>
                    </div>
                </div> : null}
                <div className="floor-table">
                    {!this.context.isMobile ? <div className="floor-table-row">
                        {columns.map((col) => {
                            if (col === 'Time') {
                                return <span style={{ fontWeight: "bold", color: "#8d570c" }} onClick={this.toggleTimes}>{col}</span>;
                            }
                            else if (col === "Seat") {
                                return <span style={{ fontWeight: "bold", color: "#8d570c" }} onClick={this.toggleSeats}>{col}</span>;
                            }
                            else {
                                return <span>{col}</span>;
                            }
                        })}
                    </div> : null}
                    {this.state.filter === "timing" ? filterTimes[this.state.timeFilter].data.map((data) => {
                        return (
                            <TableRow
                                key={data.id}
                                data={data}
                                current={new Date()}
                            ></TableRow>
                        );
                    }) : filterSeats[this.state.seatFilter].data.map((data) => {
                        return (
                            <TableRow
                                key={data.id}
                                data={data}
                                current={new Date()}
                            ></TableRow>
                        );
                    })}
                </div>
            </div>
        )
    }
}
